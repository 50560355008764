import * as React from "react"
import Svg, { Path } from "react-native-svg"

function CompleteIcon(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={29}
            height={29}
            viewBox="0 0 29 29"
            fill="none"
            {...props}
        >
            <Path
                d="M14.497 28.9c7.98 0 14.45-6.47 14.45-14.45 0-7.98-6.47-14.45-14.45-14.45C6.517 0 .047 6.47.047 14.45c0 7.98 6.47 14.45 14.45 14.45z"
                fill="#0B3C49"
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.422 9.614a1.033 1.033 0 010 1.46l-8.243 8.258a1.03 1.03 0 01-1.456 0L7.6 15.202a1.032 1.032 0 111.457-1.458l3.393 3.4 7.515-7.53a1.03 1.03 0 011.456 0z"
                fill="#fff"
            />
        </Svg>
    )
}

export default CompleteIcon;
