import * as React from "react"
import Svg, { Path } from "react-native-svg"

function StartIcon(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={29}
            height={29}
            viewBox="0 0 29 29"
            fill="none"
            {...props}
        >
            <Path
                d="M14.508 24.08c5.32 0 9.633-4.314 9.633-9.634s-4.313-9.633-9.633-9.633c-5.32 0-9.633 4.312-9.633 9.633 0 5.32 4.313 9.633 9.633 9.633z"
                fill="#0B3C49"
            />
            <Path
                d="M17.158 15.004a.688.688 0 000-1.12l-2.945-2.103a.688.688 0 00-1.088.56v4.207c0 .56.633.885 1.088.56l2.945-2.104z"
                fill="#fff"
            />
        </Svg>
    )
}

export default StartIcon;
