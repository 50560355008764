import * as React from "react"
import Svg, { Path } from "react-native-svg"

function DriverIconActive(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={29}
            viewBox="0 0 30 29"
            fill="none"
            {...props}
        >
            <Path
                d="M15 24.083a9.633 9.633 0 100-19.267 9.633 9.633 0 000 19.267z"
                fill="#0B3C49"
            />
            <Path
                d="M19.813 10.436v1.405a.402.402 0 01-.803 0v-1.405h-1.405a.401.401 0 110-.803h1.405a.803.803 0 01.803.803zm-.401 6.221a.401.401 0 00-.402.401v1.405h-1.405a.401.401 0 100 .803h1.405a.803.803 0 00.803-.803v-1.405a.402.402 0 00-.401-.4zm-7.025 1.806h-1.405v-1.405a.401.401 0 00-.802 0v1.405a.803.803 0 00.803.803h1.404a.401.401 0 100-.803zm-1.806-6.221a.401.401 0 00.402-.402v-1.404h1.404a.401.401 0 100-.803h-1.405a.803.803 0 00-.802.803v1.405a.401.401 0 00.401.4zm3.06 2.885a3.41 3.41 0 00-1.374 1.088.401.401 0 00.321.643h4.817a.401.401 0 00.32-.643 3.41 3.41 0 00-1.374-1.088 2.007 2.007 0 10-2.706 0h-.003z"
                fill="#fff"
            />
        </Svg>
    )
}

export default DriverIconActive;
