import { View, Text, StyleSheet, ImageBackground, Button, Image } from 'react-native';
import { isMobile } from '../helpers/deviceUtils';


function PrivacyPage({ navigation }) {

    return (
        <View style={styles.root}>
            <Text style={[styles.description, { paddingVertical: 10, fontSize: 22, fontWeight: "700", textAlign: "center" }]}>{`Privacy Policy `}</Text>
            <Text style={styles.description}>{`This Privacy Policy applies to your use of SimpleCruiseChoice.com services, apps, and websites that reference this Privacy Policy (collectively, the “Services”), operated by SimpleCruiseChoice.com (“SimpleCruiseChoice.com,” “we,” “our,” or “us”). This Privacy Policy applies to all visitors and users of the Services.`}</Text>
            <Text style={styles.description}>{`Because your privacy is important to us, we have created this Privacy Policy to explain our practices regarding the collection, use, and disclosure of the information that is created when you access or use the Services.`}</Text>
            <Text style={styles.description}>{`Please read the Privacy Policy carefully, as your use of the Services constitutes your agreement with this Privacy Policy and you provide us with explicit and informed consent to the use and processing of your personal information (i.e., information that could identify you) in accordance with this Privacy Policy. If you do not agree with this Privacy Policy, do not use the Services.`}</Text>
            <Text style={styles.title}>{`Who are we?`}</Text>
            <Text style={styles.description}>{`SimpleCruiseChoice.com provides an online referral service, this service can and will request personal information about yourself in order to provide the service.`}</Text>
            <Text style={styles.description}>{`This Privacy Policy does not apply to any third-party websites, services, or applications, even if they are accessible through the Services. If you have any questions or comments about this Privacy Policy, you can find our contact details on the Contact page.`}</Text>
            <Text style={styles.title}>{`To provide our Services and assure they are functioning properly, we collect certain information, including personal information. Information we collect may include:`}</Text>
            <Text style={styles.description}>{`Information Collected Automatically:`}</Text>
            <Text style={styles.description}>{`We may automatically collect Internet Protocol (“IP”) addresses, unique identifiers, online identifiers, device type and other device identifiers, operating system and browser information, activity on the Services, log data, and information collected via cookies and other similar technologies (described in greater detail below).`}</Text>
            <Text style={styles.description}>{`Information You Provide:`}</Text>
            <Text style={styles.description}>{`If you create an Account, we'll collect certain personal information, such as your name and email address and any other information you choose to provide us.`}</Text>
            <Text style={styles.description}>{`Please note, if you choose not to provide us with certain information, some areas of the Services may not function properly.`}</Text>
            <Text style={styles.description}>{`If you contact us directly through the Services, we will collect your contact information to respond.`}</Text>
            <Text style={styles.description}>{`Information from Other Sources:`}</Text>
            <Text style={styles.description}>{`We collect and process various forms of data to offer our Services and deliver you targeted advertisements as outlined in this Privacy Policy. This information is obtained from several distinct categories of sources:`}</Text>
            <Text style={styles.description}>{`A. Data Aggregators, Public Databases, and Business Partners:`}</Text>
            <Text style={styles.description}>{`- We may collect information from other sources, such as data aggregators, public databases, and our business partners, which may include information related to your unique identifiers, online interests, purchasing behavior, and certain online activities, such as websites visited, and advertisements viewed. These other sources also share city-level location information, device type, your mobile advertising identifiers, and a broad range of demographic data including your interests, age, and gender.`}</Text>
            <Text style={styles.description}>{`B. Mobile Measurement Suppliers (“MMSs”):`}</Text>
            <Text style={styles.description}>{`- MMSs provide us with information that measures your engagement with advertisements. This encompasses details on clicks, impressions, conversion duration, connection type, language, time zone, city, application version, your level of interaction, and your advertising ID.`}</Text>
            <Text style={styles.description}>{`C. Information from Advertising Technology Entities:`}</Text>
            <Text style={styles.description}>{`- We also receive information about you from of Ad Exchanges, Demand Side Platforms, Direct Marketing Platforms, Ad Networks, and other similar entities. They assist with delivering targeted advertisements and provide us with data related to your device, your use of mobile applications, your interaction with applications, websites, and advertisements, city-level location data, device type, your mobile advertising identifiers, app information, and broad demographic data.`}</Text>
            <Text style={styles.title}>{`How do we use information we collect?`}</Text>
            <Text style={styles.description}>{`Services. We may use your information in connection with providing the Services and for other related business purposes necessary in our legitimate interests.`}</Text>
            <Text style={styles.description}>{`Customization and Improvement. We may use your information to correct, enhance, and improve the Services.`}</Text>
            <Text style={styles.description}>{`Respond to Inquiries. We may use your information to provide customer support and contact you regarding your inquiries.`}</Text>
            <Text style={styles.description}>{`Surveys and Contests. We may use your information to administer your participation in surveys, contests, or sweepstakes.`}</Text>
            <Text style={styles.description}>{`Aggregated Information. We may use your information to compile aggregated, non-identifying information to conduct research and for internal and external business purposes.`}</Text>
            <Text style={styles.description}>{`Legal. We may use your information to comply with laws, prevent potentially illegal activities, protect our rights, and other uses in accordance with our Terms of Use.`}</Text>
            <Text style={styles.description}>{`Targeted Advertising and Recommendations. We may use the information we collect to serve you with targeted advertisements on the Services and elsewhere, and to provide recommendations on the Services. These advertisements and recommendations are designed to provide you with offers and information relevant to your interests. This use of this data allows us to improve the relevancy of the ads you see and the recommendations you receive, thereby enhancing your overall experience with our Services.`}</Text>
            <Text style={styles.description}>{`Promotional Communications. We may use your information to periodically send you free newsletters and e-mails that directly promote the Services — e.g., promotional information about SimpleCruiseChoice.com's features, services, and other offerings that may be of interest to you or offers that are related to the Services on behalf of our affiliates or our business partners. When you receive such promotional communications from us, you will have the opportunity to “opt-out” (either through your Account or by following the unsubscribe instructions provided in the e-mail you receive).`}</Text>
            <Text style={styles.title}>{`What technologies are used to understand how people use the Services?`}</Text>
            <Text style={styles.description}>{`Like many online operators, we use automated data collection tools such as cookies, web beacons, and other similar technologies to collect certain information to provide the Services, enable us to recognize your device or browser, and provide you with relevant offers and advertising.`}</Text>
            <Text style={styles.description}>{`Cookies are small bits of information that are stored within your device's Internet browser. Cookies store information about your activities, for example, cookies can store your session information for easy sign-in to a website you have previously visited. They enable us to make your use of the Services more enjoyable.`}</Text>
            <Text style={styles.description}>{`We, along with approved third parties, such as advertising technology companies and service providers that provide services to us and our business partners, may display targeted advertisements based on your activities and other information collected from or about you, such as your interests and your activities online. Cookies are one way that this kind of information is stored.`}</Text>
            <Text style={styles.description}>{`We, along with such approved advertising technology companies and service providers that provide services to us and our business partners, may also use cookies and similar technologies to compile aggregate data about you and to relate your use of the Services to information obtained from you or from reputable third parties. For example, if you have asked us to send you information about our upcoming services or promotions, data about your activities on the Services, collected using cookies, may allow us to limit the materials we send you to items that we believe will be of interest to you.`}</Text>
            <Text style={styles.description}>{`You can configure your Internet browser to warn you each time a cookie is being sent or to refuse cookies completely. However, please note, if you reject certain cookies, you will not have access to certain features of the Services.`}</Text>
            <Text style={styles.description}>{`Some advertisements displayed in connection with the Services may also access or place cookies on your Internet browser or use other technologies provided by third-party Internet advertisers, advertising technology companies, and service providers that perform advertising-related services.`}</Text>
            <Text style={styles.description}>{`We, along with approved advertising technology companies and service providers that provide services to us and our business partners, use other kinds of local storage, such as Local Shared Objects (also referred to as “Flash cookies”) and HTML5 local storage, in connection with the Services, including the video player. Sometimes called “browser cookies,” these technologies are similar to the cookies discussed above in that they are stored on your device and can be used to store certain information about your activities and preferences, for example, your volume/mute settings. However, these objects are stored in different parts of your device from ordinary browser cookies. Many Internet browsers allow you to disable HTML5 local storage or delete information contained in HTML5 local storage using browser controls. See your browser settings or preferences for details on how to manage HTML5 local storage. For information about disabling or deleting information contained in Local Shared Objects, please visit https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html. Please note that disabling these technologies may interfere with the performance and features of the video player.`}</Text>
            <Text style={styles.description}>{`Web Beacons (also known as web bugs, pixel tags or clear GIFs) are tiny graphics with a unique identifier that may be included on our Services for several purposes, including to deliver or communicate with cookies, to track and measure the performance of our Services, to monitor how many visitors access our Services, and to monitor the effectiveness of advertising. Unlike cookies, which are stored on the device, Web Beacons are typically embedded invisibly on web pages (or in an e-mail).`}</Text>
            <Text style={styles.description}>{`Log Data means certain information about how a person uses our Services, including both Account holders and non-Account holders (either, a “User”). Log Data may include information such as a User's Internet Protocol (IP) address, browser type, operating system, the web page that a User was visiting before accessing our Services, the pages or features of the Services to which a User browsed and the time spent on those pages or features, search terms, the links and ads on the Services that a User clicked on, progress of a User's video play, progress of a User's ad play, interactions as a video player (e.g., play/pause/seek, close captions), amount of time spent watching videos, mobile to TV screen casting behavior and other statistics. We use Log Data to administer the Services and we analyze (and may engage third parties to analyze) Log Data to improve, customize, and enhance the Services by expanding their features and functionality and tailoring them to our Users’ needs and preferences. We may use a User's IP address to generate aggregated, non-identifying information about how the Services are used.`}</Text>
            <Text style={styles.title}>{`With whom does SimpleCruiseChoice.com share the information it collects?`}</Text>
            <Text style={styles.description}>{`SimpleCruiseChoice.com is not a data broker in the principal business of selling personal information to others. However, the personal information of our users is an important aspect of our business. We share personal information only as described below with companies subject to this Privacy Policy or that follow practices at least as protective as those described herein.`}</Text>
            <Text style={styles.description}>{`Our Service Providers and Business Partners. We may engage third-party service providers and business partners to work with us to administer and provide the Services. For example, we may share your information with other people or entities in order to provide goods or services that you have requested or to complete a transaction at your request. We may also enter into agreements with outside companies that possess the technology that allows us to customize the advertising and marketing messages you receive while using the Services. Your information, including ‘clickstream’ data about your use of the Services and other online activities, may be shared with these companies so this customization can be accomplished.`}</Text>
            <Text style={styles.description}>{`Advertisers. SimpleCruiseChoice.com places advertisements both on and off the Services and also carries advertisements from other companies, often with the ability to click through to websites or properties run by these other companies. Advertisers, advertising technology companies, and service providers that perform advertising-related services for us and our business partners may use cookies, web beacons, and other technologies to collect information from or about you in order to tailor advertisements, measure advertising effectiveness, and enable other enhancements.`}</Text>
            <Text style={styles.description}>{`Other Third-Parties. We may share anonymized or aggregated data we collect from the use of the Services, such as de-identified demographic information, de-identified location information, information about the computer or device from which you access the Services, market trends and other analysis that we create based on the information we receive from you and other users. We may share information in these forms with various affiliated and unaffiliated entities, including our business partners. For example, we may share aggregate information with advertisers about the volume of use on the Services, the specific videos in which users are most interested, and the effectiveness of advertisements viewed on the Services (including the number of users who clicked on a particular advertisement). Also, we and our business partners use independent third parties to help measure viewing of content on SimpleCruiseChoice.com, and these third parties may report statistics about viewing in aggregated or other non-personally identifiable forms.`}</Text>
            <Text style={styles.description}>{`Web Analytics Service Providers. We use Google Analytics, a service provided by Google, LLC (“Google”), to gather information about how users engage with the Services. For more information about Google Analytics, please visit https://policies.google.com/technologies/partner-sites. You can opt out of Google's collection and processing of data generated by your use of the Services by going to https://tools.google.com/dlpage/gaoptout.`}</Text>
            <Text style={styles.description}>{`Both device reporting and third-party analytic services can and will be used for insights into user behavior on the services.`}</Text>
            <Text style={styles.description}>{`For Our Protection and the Protection of Others. We cooperate with government and law enforcement officials or private parties to enforce and comply with the law. We may disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate: (i) to respond to claims, legal process (including subpoenas) or request for cooperation by a government entity, whether or not legally required; (ii) to protect the property, rights and safety and the property of SimpleCruiseChoice.com or our affiliates, and each of their respective investors, directors, officers, employees, agents, and suppliers, rights and safety of a third-party, including our users, or the public in general; and (iii) to stop any activity that we consider illegal, unethical or legally actionable activity. If you notify us that you believe your legal rights have been violated by SimpleCruiseChoice.com or another user of the Services, we may provide the information that you provide to us to others to the extent that we believe it is necessary to evaluate and respond to your complaint.`}</Text>
            <Text style={styles.description}>{`In Connection with Business Transactions. Information that we collect from our users, including personal information, is a business asset. Thus, in the event of any direct or indirect reorganization, e.g., if we are acquired by a third-party as a result of a transaction such as a merger, acquisition or asset sale or if our assets are acquired by a third party in the event we go out of business or enter bankruptcy, some or all of our assets, including personal information, may be disclosed or transferred to a third-party acquirer in connection with the transaction. However, any transferee of such information will be obligated to comply with this Privacy Policy.`}</Text>
            <Text style={styles.title}>{`How does third-party advertising on SimpleCruiseChoice.com impact me?`}</Text>
            <Text style={styles.description}>{`SimpleCruiseChoice.com carries advertisements from other companies, often with the ability to click through to websites or properties run by such companies.`}</Text>
            <Text style={styles.description}>{`Clicking on or Viewing Ads. When you click on or view an advertisement, the advertiser, advertising technology companies, and service providers that perform advertising-related services for us and our business partners may collect some information from or about you. The information practices of these advertisers, advertising technology companies, and service providers that perform advertising-related services are governed by their own privacy policies and are not covered by this Privacy Policy. Please be aware that SimpleCruiseChoice.com does not warn you when you have chosen to click through to another website or property when using the Services. We recommend that you familiarize yourself with the privacy policies of such websites or properties to determine how they handle any information they separately collect from you.`}</Text>
            <Text style={styles.description}>{`Use of Cookies and Other Technology in Third-Party Advertising. Advertisers, advertising technology companies, and service providers that perform advertising-related services for us and our business partners may use cookies, web beacons, and other technologies to collect information from or about you in order to tailor advertisements, measure advertising effectiveness, and enable other enhancements. This information includes your use of the Services, websites you visited, advertisements you viewed, and other activities online. For more information on how to delete such third-party cookies, please visit “Your Choices” below.`}</Text>
            <Text style={styles.description}>{`Information Shared with Advertisers and Advertising-Related Service Providers. SimpleCruiseChoice.com may share the information in aggregated or non-personally identifiable forms with advertisers and service providers that perform advertising-related services for us and our business partners in order to tailor advertisements, measure advertising effectiveness, and enable other enhancements. This information includes your use of the Services, websites you visited, advertisements you viewed, and your other activities online. We may engage service providers and business partners to enhance the information we have about you by combining it with information, including personal information, that they have obtained from you. This allows us to deliver ads to you that are more tailored to your interests. By combining this data, we can use Demand Side Platforms (DSPs) to serve interest-based advertising. Working with these third parties is critical for optimizing our services and providing you with a more personalized and relevant experience.`}</Text>
            <Text style={styles.description}>{`However, our agreements with these advertisers and service providers that perform advertising-related services limit the types of information shared with these companies and how they may use it. They are contractually prohibited from using any personal information we share with them for any purpose other than the specific contractual purpose we have established with them. They are also contractually prohibited from disclosing or sharing this information with any third party.`}</Text>
            <Text style={styles.title}>{`Your Choices:`}</Text>
            <Text style={styles.description}>{`Opting Out of Third-Party Behavioral Advertising. The advertisers, advertising technology companies, and service providers that perform advertising-related services for us and our business partners may be members of the Network Advertising Initiative (“NAI”), a cooperative of online marketing companies that offers a centralized tool for opting out of behavioral advertising delivered by each of its member companies. If you would like to obtain more information about the NAI and make choices about their members’ use of your information, please visit https://optout.networkadvertising.org/. Some of the third parties that collect information on the Services to provide more relevant advertising to you may participate in the Self-Regulatory Program for Online Behavioral Advertising. This program offers a single location where users can make choices about the use of their information for ad targeting. To opt-out of third-party cookies, please go to https://www.aboutads.info/choices. To opt-out of the use of your mobile device ID for targeted advertising, please see https://www.aboutads.info/appchoices. Please note, even if you opt out through AdChoices, we may still collect and use information that is not considered personal information regarding your activities on our Services and/or information from the advertisements on third-party websites for non-targeted advertising purposes, such as to determine the effectiveness of the advertisements.`}</Text>
            <Text style={styles.description}>{`Opting Out of Targeted Advertising. You have the right to opt out of our processing your personal information for targeted advertising. If you do not wish to receive targeted advertisements from us, you may make a request to opt out by contacting SimpleCruiseChoice.com's Data Protection Officer online here at www.dataoptoutsubmission.com. Please include your name, email address, and a clear statement that you wish to opt out of targeted advertising. Upon receipt of your request, SimpleCruiseChoice.com will take reasonable steps to ensure that you are no longer targeted with personalized advertisements. Please note that even if you opt out of targeted advertising, you may still receive general advertisements that are not based on your interests.`}</Text>
            <Text style={styles.title}>{`Your Rights`}</Text>
            <Text style={styles.description}>{`We do not currently respond to “Do Not Track” signals or other similar opt-out mechanisms, other than those specifically set forth in this Privacy Policy, including general Internet browser “Do Not Track” settings.`}</Text>
            <Text style={styles.description}>{`You can access, update, and correct certain Account information that you have provided to us through your Account settings. Please note, although your device settings may allow you to deny the collection or processing of your information, doing so may cause certain portions of the Services to lose functionality.`}</Text>
            <Text style={styles.title}>{`Push Notifications.`}</Text>
            <Text style={styles.description}>{`We may send push notifications to your mobile device, with your permission, to provide Service information and updates, promotional communications, and other related business information. You can opt-out of push notifications by changing the settings within your mobile device. However, this may affect certain functionality with the Services.`}</Text>
            <Text style={styles.title}>{`Geo-Location Information.`}</Text>
            <Text style={styles.description}>{`When you use the Services, with your permission, we may collect rough geo-location information for localization accuracy with respect to providing certain Services. We will only collect such information if the permissions in your mobile device allow communication of this information. You can opt-out of making this information available to us by changing the settings within your mobile device, however, this may affect key functionality of the Services.`}</Text>
            <Text style={styles.title}>{`Additional Choices for California Residents`}</Text>
            <Text style={styles.description}>{`This section supplements the Privacy Policy and only applies to California residents. The California Consumer Privacy Act (“CCPA”) allows California residents, with verification, to request that a business who collects California consumers’ personal information to give consumers access to the specific pieces and categories of personal information that the business has collected about the consumer, the sources for that information, the purposes for collecting the information, and the third parties with which the information was shared. California residents also have the right to submit a request for deletion of information under certain circumstances, and to opt out of the “sale” of their personal information.`}</Text>
            <Text style={styles.description}>{`This notice describes those rights, including the method through which you may submit a request to exercise those rights, and provides the disclosures regarding personal information required by the CCPA.`}</Text>
            <Text style={styles.description}>{`The CCPA requires us to provide the categories of personal information (as defined in the CCPA) that we collect, or have collected in the last 12 months, which include:`}</Text>
            <Text style={styles.description}>{`Identifiers (including IP addresses, name, username, email address, unique personal identifiers, and other similar identifiers)`}</Text>
            <Text style={styles.description}>{`The categories of personal information listed in the California Customer Records statute (Cal. Civ. Code § 1798.80) (including name, contact information)`}</Text>
            <Text style={styles.description}>{`Commercial information (including services purchased or considered, payment history, transaction history information)`}</Text>
            <Text style={styles.description}>{`Geo-location information`}</Text>
            <Text style={styles.description}>{`Internet and other electronic activity information (including browsing history, search history, online behavior, and data regarding your interactions with websites and apps)`}</Text>
            <Text style={styles.description}>{`Inference information (including additional products or services we think may be of interest to you)`}</Text>
            <Text style={styles.description}>{`We use and disclose the above categories of personal information consistent with the business purposes described within this Privacy Policy and to provide advertisements we think you might be interested in.`}</Text>
            <Text style={styles.description}>{`Further, because the CCPA defines the term “sale” broadly, personal information collected by third parties through our Services for the purpose of providing targeted advertising may constitute a “sale.” Accordingly, we “sold” the following categories of personal information within the last 12 months: Identifiers; Internet and other electronic activity information; and Inference information to advertising networks and analytics providers.`}</Text>
            <Text style={styles.title}>{`What are my rights?`}</Text>
            <Text style={styles.description}>{`Under the CCPA you are granted certain rights with respect to your personal information. These rights are not absolute, are subject to exceptions, and we may be required or permitted by law to decline your request. Only you as a resident of the State of California, a person that you authorize to act on your behalf, or an entity registered with the California Secretary of State that you authorize to act on your behalf, may exercise these rights. We are permitted to reject your request if we reasonably do not believe you are a resident of the State of California.`}</Text>
            <Text style={styles.description}>{`Under the CCPA, California residents may request:`}</Text>
            <Text style={styles.description}>{`The categories of personal information we collect or disclose, or have collected or disclosed about you`}</Text>
            <Text style={styles.description}>{`The specific pieces of personal information we have collected about you`}</Text>
            <Text style={styles.description}>{`The categories of sources of personal information we collect or disclose, or have collected or disclosed about you`}</Text>
            <Text style={styles.description}>{`The business or commercial purpose for which we collect personal information`}</Text>
            <Text style={styles.description}>{`The categories of third parties with whom we share personal information`}</Text>
            <Text style={styles.description}>{`The categories of information that we sell or disclose to third parties`}</Text>
            <Text style={styles.description}>{`That we correct information we have collected about you`}</Text>
            <Text style={styles.description}>{`That we do not share your personal information for cross-contextual advertising purposes`}</Text>
            <Text style={styles.description}>{`To limit our use of sensitive personal information that we may have collected about you`}</Text>
            <Text style={styles.description}>{`That we delete personal information that we have collected from you, subject to limitations and exemptions under the laws`}</Text>
            <Text style={styles.description}>{`To opt-out of the sale of personal information`}</Text>
            <Text style={styles.title}>{`Non-discrimination.`}</Text>
            <Text style={styles.description}>{`Pursuant to the CCPA, we cannot discriminate against you because you elect to exercise any of the foregoing rights, such as your right to delete. Please note, if you do elect to delete your personal information you will be treated as a new viewer and will no longer have access to your existing queue, watch list, or personalized recommendations.`}</Text>
            <Text style={styles.title}>{`Verifying requests; California residency requirement.`}</Text>
            <Text style={styles.description}>{`The rights described in this section of the Privacy Policy, including the right to opt-out of the sale of personal information, are limited to residents of the State of California. We may require additional information from you to verify that you are a California resident or may reject your request if you cannot provide it. We reserve the right to require additional proof of your identity and California residency, including government identification and/or a utility bill. We further reserve the right to reject requests that we reasonably believe are from non-California residents, or are fraudulent, excessive, or burdensome. Moreover, such requests can only be made two times within a 12-month period at no charge. We reserve the right to deny or charge a fee with respect to fulfilling additional requests during such 12-month period. In addition, any information we provide will only include information that we have collected over the previous 12 months from the date of such request.`}</Text>
            <Text style={styles.description}>{`You may designate, in writing, through a power of attorney, or through registration with the California Secretary of Statement, an authorized agent to make requests to exercise your rights on your behalf. In addition to the foregoing, we will require the agent to provide proof that they are authorized to act on your behalf. We will attempt to respond to all verifiable requests within 45 days of receipt of such requests.`}</Text>
            <Text style={styles.description}>{`If you are a California resident and would like to exercise any of the above rights pursuant to the CCPA, please contact us via email at privacy@SimpleCruiseChoice.com.`}</Text>
            <Text style={styles.title}>{`How does SimpleCruiseChoice.com protect the information it collects and how long does it retain such information?`}</Text>
            <Text style={styles.description}>{`We use reasonable physical, technical, and administrative measures to safeguard information in our possession against loss, theft and unauthorized use, disclosure, or modification. For example, passwords and other sensitive information are encrypted in transit. We also take steps to ensure that only those SimpleCruiseChoice.com employees, agents, and contractors who have a legitimate business reason to obtain access to your information are granted access.`}</Text>
            <Text style={styles.description}>{`Despite these efforts, please note that no data transmission or storage can be guaranteed to be 100% secure. We want you to feel confident using the Services but we cannot ensure or warrant the security of any information you transmit to us. Please refer to the U.S. Federal Trade Commission's website for information on how to protect yourself from identity theft.`}</Text>
            <Text style={styles.description}>{`We will retain your personal information for no longer than is necessary to fulfill the purposes for which the personal information was collected, unless required or permitted by law to retain it longer.`}</Text>
            <Text style={styles.title}>{`SimpleCruiseChoice.com and Children`}</Text>
            <Text style={styles.description}>{`The Services are not directed to nor intended for children under the age of 18. SimpleCruiseChoice.com does not permit the registration of, nor does SimpleCruiseChoice.com knowingly collect, use, or disclose personal information from anyone under 18 years of age. If we determine upon collection that a user is under 18 years of age, we will not use or maintain such personal information without parental or guardian consent. If you learn that your child has provided us with personal information without your consent, you may notify us at privacy@SimpleCruiseChoice.com and we will promptly take steps to delete such information from our records.`}</Text>
            <Text style={styles.title}>{`Do any special considerations apply to visitors/users from outside the United States?`}</Text>
            <Text style={styles.description}>{`SimpleCruiseChoice.com is an online video service based in the U.S., and our offices are headquartered in the United States. Currently, the Services are available to users located within the United States and Canada. As such, your personal information may be transferred to, and maintained on, computer systems located outside of your state, province, country, or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. If you're located outside the United States and choose to provide your personal information to us, you acknowledge and agree that we may transfer your personal information to the United States and process it there.`}</Text>
            <Text style={styles.title}>{`What happens when SimpleCruiseChoice.com changes its Privacy Policy?`}</Text>
            <Text style={styles.description}>{`Any information that is collected via our Services is covered by the Privacy Policy in effect at the time such information is collected. We may revise this Privacy Policy from time to time. If we make any material changes to this Privacy Policy, we'll update the “Effective as of” date above to indicate when those changes will become effective. If the material revisions concern practices where we have represented that we will obtain your consent to use or disclose your personal information, we will obtain your consent before taking any action inconsistent with those representations.`}</Text>
            <Text style={styles.title}>{`Questions?`}</Text>
            <Text style={styles.description}>{`If you have any questions or comments about this Privacy Policy, contact us through the contact@SimpleCruiseChoice.com.`}</Text>

        </View>
    );
}

const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            padding: 50
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            position: "relative",
            paddingVertical: 10,
        },
        description: {
            color: "#0B3C49",
            fontSize: 16,
            fontWeight: "500",
            position: "relative",
            paddingVertical: 10,
        },
    }
)

export default PrivacyPage;
