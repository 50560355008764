import * as React from "react"
import Svg, { Path } from "react-native-svg"

function DriverIconNormal(props) {
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={29}
            height={29}
            viewBox="0 0 29 29"
            fill="none"
            {...props}
        >
            <Path
                opacity={0.5}
                d="M20.698 9.286v1.806a.516.516 0 11-1.033 0V9.286H17.86a.516.516 0 110-1.032h1.806a1.032 1.032 0 011.033 1.032zm-.517 8a.516.516 0 00-.516.515v1.807H17.86a.516.516 0 100 1.032h1.806a1.032 1.032 0 001.033-1.032V17.8a.516.516 0 00-.517-.516zm-9.03 2.322H9.345V17.8a.516.516 0 10-1.033 0v1.807a1.032 1.032 0 001.033 1.032h1.806a.516.516 0 100-1.032zm-2.322-8a.516.516 0 00.516-.516V9.286h1.806a.516.516 0 100-1.032H9.345a1.032 1.032 0 00-1.033 1.032v1.806a.516.516 0 00.517.516zm3.934 3.71a4.383 4.383 0 00-1.767 1.399.516.516 0 00.413.826H17.6a.516.516 0 00.413-.826 4.383 4.383 0 00-1.767-1.399 2.582 2.582 0 00-3.198-4.034 2.581 2.581 0 00-.282 4.034h-.004z"
                fill="#000"
            />
        </Svg>
    )
}

export default DriverIconNormal;
