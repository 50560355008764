import { View, Text, StyleSheet, Linking } from 'react-native';
import { useNavigate } from 'react-router-dom';


const Footer = (props) => {
    const navigate = useNavigate()

    return (
        <View style={props.style}>
            <Text style={[styles.footer, props.textStyle]}><Text onPress={() => {
                navigate("/terms");
            }}>Terms</Text>
                <Text>  |  </Text>
                <Text onPress={() => {
                    navigate("/privacy");
                }}>Privacy</Text>
                <Text>  |  </Text>
                <Text onPress={() => {
                    Linking.openURL(`mailto: contact@SimpleCruiseChoice.com`);
                }}>Contact</Text>
            </Text>
        </View>);
}

const styles = StyleSheet.create(
    {
        footer: {
            color: "white",
            fontSize: 16,
            fontWeight: "500",
            position: "relative"
        },
    }
)

export default Footer;